import React from "react";
import { Link } from "react-router-dom";

import DropdownMenu from "../DropdownMenu";

import "./header.scss";

export default () => (
    <header id="header">
        <Link className="main-logo" to="/">
            <img src="../../assets/imgs/logo.png" alt="Logo" />
        </Link>
        <nav className="header-menu">
            <DropdownMenu title="My WomanCare" items={[{path: "/", label: "1"}, {path: "/", label: "2"}]} />
        </nav>
    </header>
);
