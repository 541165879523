// const production = {
//     apiUrl: "http://womancarebackendprod-env.eba-dpukbi8e.eu-west-3.elasticbeanstalk.com/api"
// }

const staging = {
    apiUrl: "http://womancarebackendprod-env.eba-dpukbi8e.eu-west-3.elasticbeanstalk.com/api",
};

const dev = {
    apiUrl: "http://localhost:3000/api",
};

export const environment = process.env.NODE_ENV === "development" ? dev : staging;
