import React from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

import "./home.scss";
import Container from "../../components/style/container.component";
import Content from "../../components/style/content.component";
import Button from "../../components/forms/button.component";
import Text from "../../components/style/text.component";
import { useLogin } from "../../utils/auth.utils";

const HomeScreen = () => {
    const { isLogin } = useLogin();

    return isLogin ? (
        <Redirect to="/connected" />
    ) : (
        <div></div>
    );
};

export default HomeScreen;
