import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import Routes from "./routes";
import Header from "../components/structure/Header";
import Footer from "../components/structure/Footer";

const Page = ({ component }) => {
    const PageComponent = component;
    return (
        <>
            <Header />
            <PageComponent />
            <Footer />
        </>
    );
};

const PagesNavigation = () => {
    return (
        <Router>
            <Switch>
                {Routes.map((route) => (
                    <Route
                        key={route.path}
                        exact
                        path={route.path}
                        component={() => <Page component={route.component} />}
                    />
                ))}
                <Redirect to="/404" />
            </Switch>
        </Router>
    );
};

export default PagesNavigation;
