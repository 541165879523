import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import "./dropdown-menu.scss";

// import { TouchableRow, Col } from "../../styles/common.styles";
// import AppText from "../style/app-text.component";

type Item = {
    label: string;
    path: string;
};

export default (props: { title: string; items: Item[] }) => {
    const [showOptions, setShowOptions] = useState(false);

    return (
        <div className="dropdown-menu">
            <div className="title" onClick={() => setShowOptions((prev) => !prev)}>
                {props.title} <img className="toggle-icon" src="" alt="" />
            </div>
            {showOptions && (
                <div className="items">
                    {props.items.map((item, index) => (
                        <Link key={index} className="item" to={item.path} onClick={() => setShowOptions(false)}>
                            {item.label}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};
