import HomeScreen from "./home/home.screen";
import SigninScreen from "./signin/signin.screen";
import SignupScreen from "./signup/signup.screen";
import ConnectedScreen from "./connected/connected.screen";
import NotFoundScreen from "./404/not-found.screen";

export default [
    {
        path: "/",
        component: HomeScreen,
    },
    {
        path: "/signin",
        component: SigninScreen,
    },
    {
        path: "/signup",
        component: SignupScreen,
    },
    {
        path: "/connected",
        component: ConnectedScreen,
    },
    {
        path: "/404",
        component: NotFoundScreen,
    },
];
